<template>
  <div>
    <div class="column">
      <div class="col">
        <q-input v-model.number="form" lazy-rules filled ref="input"
                 :rules="[
                     val => !isNaN(val) || 'number only',
                     val => val <= vmax || this.template_text.ALLOWEDMAX+' ' +vmax,
                        val => val >= vmin || this.template_text.ALLOWEDMIN+' ' +vmin ,
                         isMandatory]"

        />
      </div>
      <div class="col self-end" v-if="forcenext||dnext">
        <q-btn color="secondary"  push icon-right="navigate_next" :label="template_text.NEXT" @click="onNext"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    form: {
      handler: function(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    }
  },

  name: "numerical_input",
  data(){
    return {
      form:this.value||this.attribut.vdefault,
      vmin:this.attribut.vmin||0,
      vmax:this.attribut.vmax||10000,
    }

  },
  computed:{
  },
  props:{
    value:Number,
    attribut:Object,
    forcenext:Boolean,
    dnext:Boolean,
    template_text:Object,
    mandatory:Boolean,
    language:String,
  },
  methods:{
    async validate(){
      return await this.$refs.input.validate()
    },
    isMandatory(val){
      if(this.mandatory){
        return !!val || this.template_text.MANDATORYFIELD
      }
      return true
    },
    async onNext(){
      if(await this.validate()) this.$emit('next');

    }
  },
}
</script>

<style scoped>

</style>
